import './FirstView.scss';
import { BurgerSVG, CrossSVG, HumbilitySVG } from 'components/svg/svg';
import { useEffect, useState } from 'react';
import HumbleList from 'components/HumbleList/HumbleList';

function FirstView() {
  const [mobileMenuPos, setMobileMenuPos] = useState(-400);

  useEffect(() => {
    window.onscroll = closeMobileMenu;
    document.onclick = closeMobileMenu;
  }, []);

  const closeMobileMenu = () => {
    setMobileMenuPos(-400)
  }

  const openMobileMenu = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setMobileMenuPos(0);
    ev.stopPropagation();
  }

  return (
    <div className="first-view">

      <div className='first-view__wrapper'>
        <div className='contents'>
          <div className='landing-wrapper'>
            <header>
              <HumbilitySVG />
              <div className='links'>
                <a href="#what-we-do">What we do</a>
                <a href="#team">Team</a>
                <a href="#grow">Grow with us</a>
                <a href="https://www.linkedin.com/company/humbility-trading/about/">LinkedIn</a>
              </div>
              {mobileMenuPos !== 0 && <div className='burger' onClick={(ev) => openMobileMenu(ev)}><BurgerSVG /></div>}
            </header>

            <div className='h1-wrapper'>
              <h1>FIRST LARGE SCALE CRYPTO TRADING FIRM IN LITHUANIA</h1>
            </div>
          </div>


          <div id='what-we-do' className='contents__main'>
            <HumbleList title='WE DO' titleCrossed='' items={[
              'Collect and analyse vast amounts of market data',
              'Build mathematical models backed by real-world simulations',
              'Run high frequency arbitrage trading bots'
            ]} />
            <HumbleList title='WE DO' titleCrossed='N&apos;T' items={[
              'Speculate or gamble',
              'Take long or short positions',
              'Predict assets\' future performance'
            ]} />
          </div>
        </div>



        {<div className='mobile-menu' style={{ right: `${mobileMenuPos}px` }} onClick={(ev) => ev.stopPropagation()}>
          <div className='cross' onClick={() => closeMobileMenu()}><CrossSVG/></div>

          <div className='mobile-menu__top'>
            <a href="#what-we-do" onClick={() => closeMobileMenu()}>What we do</a>
            <a href="#team" onClick={() => closeMobileMenu()}>Team</a>
            <a href="#grow" onClick={() => closeMobileMenu()}>Grow with us</a>
          </div>

          <div className='mobile-menu__middle'>
            <a href="mailto:dev@humbility.io" rel="noreferrer" target="_blank">dev@humbility.io</a>
            <a href="https://www.linkedin.com/company/humbility-trading/about/" rel="noreferrer" target="_blank">LinkedIn</a>
          </div>

          <div className='mobile-menu__bot'>2022 "UAB Humbility" J. Balcikonio str. 9, Vilnius, Lithuania</div>
        </div>}
      </div>

    </div>
  );
}

export default FirstView;
