import HumbleList from 'components/HumbleList/HumbleList';
import {
  AmazonSVG,
  BentleySVG,
  CambridgeSVG,
  DanskeSVG,
  ETHSVG,
  ISMSVG,
  KTUSVG,
  LicejusSVG,
  LSESVG,
  TrafiSVG,
  UnitySVG,
  VintedSVG,
  VUSVG,
  WixSVG,
} from "components/svg/svg";
import './AboutTeam.scss';

function AboutTeam() {
  return (
    <div className='about-team'>
      <div className='contents' id='team'>
        <div className='about-team__top'>
          <div className='title'>OUR TEAM</div>
          <HumbleList title='THE WAY WE WORK' titleCrossed = ''
            items={[
              'Developing asynchronous processes and ensuring they run smoothly',
              'Diving deep into latencies, choosing the best infrastructure to win',
              'Optimizing our code down to microsecond level'
            ]} />
        </div>

        <div className='about-team__bot'>
          <div className='box blue'>
            <div className='label'>WE WORKED IN</div>
            <div className='svg-wrapper'><AmazonSVG /></div>
            <div className='svg-wrapper'><TrafiSVG /></div>
            <div className='svg-wrapper'><BentleySVG /></div>
            <div className='svg-wrapper'><DanskeSVG /></div>
            <div className='svg-wrapper'><UnitySVG /></div>
            <div className='svg-wrapper'><WixSVG /></div>
            <div className='svg-wrapper'><VintedSVG /></div>
          </div>

          <div className='box green'>
            <div className='label'>WE STUDIED IN</div>
            <div className='svg-wrapper'><CambridgeSVG /></div>
            <div className='svg-wrapper'><LSESVG /></div>
            <div className='svg-wrapper'><VUSVG /></div>
            <div className='svg-wrapper'><ETHSVG /></div>
            <div className='svg-wrapper'><KTUSVG /></div>
            <div className='svg-wrapper'><ISMSVG /></div>
            <div className='svg-wrapper'><LicejusSVG /></div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default AboutTeam;
